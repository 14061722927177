exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refunds-and-cancellations-js": () => import("./../../../src/pages/refunds-and-cancellations.js" /* webpackChunkName: "component---src-pages-refunds-and-cancellations-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-register-old-js": () => import("./../../../src/pages/register-old.js" /* webpackChunkName: "component---src-pages-register-old-js" */),
  "component---src-pages-register-order-id-js": () => import("./../../../src/pages/register/orderId.js" /* webpackChunkName: "component---src-pages-register-order-id-js" */),
  "component---src-pages-register-register-js": () => import("./../../../src/pages/register/register.js" /* webpackChunkName: "component---src-pages-register-register-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

